import { useUserStore } from '@/stores/user';
import { useAnonymousUserStore } from '@/stores/anonymousUser';
import { useAccountStore } from '@/stores/account';

export function ifNotAuthenticatedRedirect(to, from, next) {
  const userStore = useUserStore();

  if (!userStore.isAuthenticated) {
    userStore.setRedirectAfterLogin(to.fullPath); 
    next({ name: 'login' });
  } else {
    next();
  }
}

export function ifNotAuthenticatedAnonymousRedirect(to, from, next) {
    const userStore = useUserStore();
  
    if (!userStore.isAuthenticated) {
        const anonymousUserStore = useAnonymousUserStore();

        if (!anonymousUserStore.isAuthenticated) {
            anonymousUserStore.setRedirectAfterLogin(to.fullPath);
            next({ name: 'anonymous-login' });
        } else {
            next();
        }
    } else {
      next();
    }
  }

export function ifAuthenticatedRedirect(to, from, next) {
    const userStore = useUserStore();
  
    if (userStore.isAuthenticated) {
      next({ name: 'home' });
    } else {
      next();
    }
}

export function hasQueryParamPasswordSelect(to, from, next) {
    if (!to.query.r) {
      next({ name: 'passwordReset' });
    } else {
      next();
    }
}

export async function hasAccountId(to, from, next) {
    const accountStore = useAccountStore();
    const accountId = to.query.account_id;
  
    if (accountId) {
      accountStore.changeAccount(accountId);
    }

    if (accountStore.hasAccountId) {
        next();
    } else {
        next({ name: 'accounts' });
    }
}