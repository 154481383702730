<template>
    <div :class="['search', { loading, error: !!error }]">
        <slot v-if="!loading && !error"></slot>

        <div v-if="loading" class="loading-section">
            <div class="loading-spinner"></div>
            <div class="loading-text">{{ $t('common.loading_1') }}</div>
        </div>

        <div v-if="error" class="error-section">
            <div class="error-message">{{ error.message }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SearchViewBase',
        props: {
            loading: {
                type: Boolean,
                required: true
            },
            error: {
                type: Object,
                required: false
            }
        }
    }
</script>
