<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="common.loading_1"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_same_address.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                                <td>{{ $t('common.company_name') }}</td>
                                <td>{{ $t('common.coc') }}</td>
                                <td>{{ $t('common.phone') }}</td>
                                <td>{{ $t('common.website') }}</td>
                                <td>{{ $t('common.address') }}</td>
                                <td>{{ $t('common.postcode') }}</td>
                                <td>{{ $t('common.city') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company" v-for="company in sameAddress.details" :key="company.id" @click="goToCompany(company.id, company.ch || false)">
                                <td>
                                    <CompanyLogo 
                                        :domain="company.domain ? company.domain : null"
                                        :company_name="company.name ? company.name : company.domain ? company.domain : null"
                                        class="company-image"
                                    />
                                </td>
                                <td class="company-name">{{ company.name }}</td>
                                <td>{{ company.id }}</td>
                                <td>{{ company.phone_international }}</td>
                                <td class="website">{{ company.url }}</td>
                                <!-- <td><a v-if="company.phone_international" :href="'tel:' + company.phone_international.replace('+', '00').replaceAll(' ', '')" target="_blank" rel="noreferrer external">{{ company.phone_international }}</a></td>
                                <td class="website"><a v-if="company.url" :href="company.url" target="_blank" rel="noreferrer external">{{ company.url }}</a></td> -->
                                <td class="address">{{ company.address }}</td>
                                <td>{{ company.postcode }}</td>
                                <td>{{ company.city }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';

    export default {
        name: "ProspectSameAddress",
        components: {
            WidgetSection,
            CompanyLogo
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false
            }
        },
        computed: {
            hide() {
                return !this.sameAddress || (!this.loading && this.loaded && (!this.sameAddress.details || this.sameAddress.details.length < 1));
            },
            sameAddress() {
                return useProspectStore().sameAddress;
            },
        },
        methods: {
            async fetchSameAddress() {
                if (!this.sameAddress || (this.sameAddress.api_urls && this.sameAddress.api_urls.length < 1) || this.loaded) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.sameAddress.api_urls[0]);
                    this.sameAddress.details = response.data.companies;
                    this.loaded = true;
                } catch {
                    this.sameAddress.details = null;
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                }
            },
            async handleLoadingStart() {
                if (!this.loaded) {
                    await this.fetchSameAddress();
                    
                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            },
            goToCompany(id, hash = false) {
                if(hash) {
                    this.$router.push({ name: 'search', params: { id: id + '_' + hash } });
                } else {
                    this.$router.push({ name: 'search', params: { id } });
                }
            },
        },
        mounted() {
            if(!this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchSameAddress();
            }
        }
    }
</script>