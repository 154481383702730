<template>
    <div :class="[cssAutoSuggest, cssForm, isSuggestionListOpen ? 'active' : '']">
       <div class="autosuggest-input" ref="inputContainer">
            <CustomInput
                v-model="search"
                inputKey="company_search"
                @update:modelValue="onChanged"
                @keydown.down.prevent="highlightNext"
                @keydown.up.prevent="highlightPrevious"
                @keydown.enter.prevent="selectHighlighted"
                :showLabel="false"
                cssClass="input-search"
                @focus="onFocus"
                @blur="onBlur($event)"
                :autocomplete="false"
            />

            <div class="autosuggest-options" v-if="isSuggestionListOpen" ref="suggestionsList" :style="{ maxHeight: suggestionListMaxHeight }">
                <ul @click.stop>
                    <li v-if="loading" class="loading-spinner">
                        <div class="loading-spinner-type-1 primary"></div>
                    </li>

                    <li v-else-if="!loading && suggestions.length === 0 && search" class="no-results">
                        {{ 'There are no results for "' + search + '"' }}
                    </li>

                    <li
                        v-else
                        v-for="(suggestion, index) in suggestions" 
                        :key="suggestion.term"
                        :class="{ 'highlighted': index === highlightedIndex }"
                        @click="selectSuggestion(suggestion)"
                        @mouseover="highlightIndex(index)"
                    >
                        <div class="left">
                            <CompanyLogo
                                :domain="suggestion.domain ? suggestion.domain : null"
                                :company_name="suggestion.name ? suggestion.name : suggestion.domain ? suggestion.domain : null"
                            />
                        </div>
                        <div class="right">
                            <div class="top">
                                <span class="name">{{ suggestion.name ? suggestion.name : '-' }}</span>
                                <span v-if="suggestion.coc" class="coc">{{ $t('common.coc_short') + ': ' + suggestion.coc }}</span>
                            </div>
                            <div class="bottom" v-if="generateTerm(suggestion) || suggestion.domain">
                                <span v-if="generateTerm(suggestion)" class="term">{{ generateTerm(suggestion) }}</span>
                                <span v-if="suggestion.city || suggestion.country_code" class="location">{{ (suggestion.city ? suggestion.city : '') + (suggestion.city && suggestion.country_code ? ', ' : '') + (suggestion.country_code ? suggestion.country_code.toUpperCase() : '') }}</span>
                                <span v-if="suggestion.domain" class="domain">{{ suggestion.domain }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { fetchSuggestions } from '@/services/ppApi';
    import { useAccountStore } from '@/stores/account';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';

    export default {
        name: "SearchBasic",
        components: {
            CustomInput,
            CompanyLogo
        },
        props: {
            query: {
                type: String,
                default: '',
                required: false
            },
            cssAutoSuggest: {
                type: String,
                default: 'autosuggest-type-3',
                required: false
            },
            cssForm: {
                type: String,
                default: 'form-type-3',
                required: false
            }
        },
        data() {
            return {
                loading: false,
                search: '',
                suggestions: [],
                highlightedIndex: -1,
                suggestionListMaxHeight: null,
                label: `fields.company_search.label`,
                isSuggestionListOpen: false,
            };
        },
        methods: {
            onChanged: debounce(function(newValue) {
                this.getSuggestions(newValue);
            }, 300),
            async getSuggestions(newValue) {
                if(!newValue || newValue.trim() === '' || newValue.length < 2) {
                    this.suggestions = [];
                    this.loading = false;
                    this.isSuggestionListOpen = false;
                    return;
                }

                this.loading = true;
                this.isSuggestionListOpen = true;

                try {
                    const accountStore = useAccountStore();
                    const account_id = accountStore.account?.account_id ?  accountStore.account.account_id : 'a0000'; // Set default for authenticated users.
                    const response = await fetchSuggestions({
                        account_id,
                        type: 'company',
                        q: newValue
                    });
                    this.suggestions = response.data.suggest;
                } catch {
                    this.suggestions =  [];
                } finally {
                    this.updateSuggestionListHeight();
                    this.highlightedIndex = -1;
                    this.loading = false;
                }
            },
            onFocus() {
                this.$emit('search-focus');

                if(this.search !== '') {
                    this.isSuggestionListOpen = true;
                }
            },
            onBlur(event) {
                setTimeout(() => {
                    if (
                        !this.$refs.suggestionsList ||
                        !this.$refs.suggestionsList.contains(event.relatedTarget)
                    ) {
                        this.$emit('search-blur');
                        this.closeSuggestionList();
                    }
                }, 500); // Allow clicks on pointer devices
            },
            updateSuggestionListHeight() {
                this.$nextTick(() => {
                    if (this.$refs.inputContainer && this.$refs.suggestionsList) {
                        const inputRect = this.$refs.inputContainer.getBoundingClientRect();

                        let viewportHeight;
                        if (window.visualViewport) {
                            viewportHeight = window.visualViewport.height;
                        } else {
                            viewportHeight = document.documentElement.clientHeight;
                        }

                        const bottomPagePaddingPx = 16;
                        const availableSpaceBelow = viewportHeight - inputRect.bottom - bottomPagePaddingPx;
                        const defaultMaxHeight = 500; // or any value you prefer
                        const maxHeight = Math.min(availableSpaceBelow, defaultMaxHeight);
                        this.suggestionListMaxHeight = maxHeight + 'px';
                    }
                });
            },
            generateTerm(suggestion) {
                switch (suggestion.term_match) {
                    case 'name':
                        if(suggestion.term !== suggestion.name) {
                            return '"' + suggestion.term + '"';
                        }
                        break;
                    case 'domain':
                        if(suggestion.term !== suggestion.domain) {
                            return suggestion.term;
                        }
                        break;
                }
                return false;
            },
            openCompanyProfile(id) {
                if(id) {
                    this.$router.push({ name: 'search', params: { id: id } });
                }
            },
            selectSuggestion(suggestion) {
                this.search = suggestion.term;
                this.closeSuggestionList();
                this.openCompanyProfile(suggestion.id);
            },
            handleClickOutside(event) {
                if (
                    this.$refs.inputContainer && !this.$refs.inputContainer.contains(event.target) && 
                    this.$refs.suggestionsList && !this.$refs.suggestionsList.contains(event.target)
                ) {
                    this.closeSuggestionList();
                }
            },
            closeSuggestionList() {
                this.loading = false;
                this.isSuggestionListOpen = false;
                this.highlightedIndex = -1;
            },
            highlightNext() {
                if (this.highlightedIndex < this.suggestions.length - 1) {
                    this.highlightedIndex++;
                }
            },
            highlightPrevious() {
                if (this.highlightedIndex > 0) {
                    this.highlightedIndex--;
                }
            },
            selectHighlighted() {
                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
                    this.selectSuggestion(this.suggestions[this.highlightedIndex]);
                } else if (this.highlightedIndex === -1 && this.suggestions.length < 1) {
                    this.getSuggestions(this.search);
                }
            },
            highlightIndex(index) {
                this.highlightedIndex = index;
            },
            initDefault() {
                if(this.query && this.query.trim() != '') {
                    this.search = this.query;
                }
            }
        },
        watch: {
            suggestions(newVal) {
                if (newVal.length > 0) {
                    this.updateSuggestionListHeight();
                }
            }
        },
        mounted() {
            this.initDefault();
            document.addEventListener('click', this.handleClickOutside);
            window.addEventListener('resize', this.updateSuggestionListHeight);
            window.addEventListener('scroll', this.updateSuggestionListHeight);
        },
        unmounted() {
            document.removeEventListener('click', this.handleClickOutside);
            window.removeEventListener('resize', this.updateSuggestionListHeight);
            window.removeEventListener('scroll', this.updateSuggestionListHeight);
        },
    }
</script>