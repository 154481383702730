import axios from 'axios';
import { useUserStore } from '@/stores/user';
import { useAnonymousUserStore } from '@/stores/anonymousUser';
import { confirmAuthPopup } from '@/utils/confirmAuthPopup';

export const bdApi = axios.create({
  baseURL: 'https://api.bedrijfsdata.nl/v1.01'
});

bdApi.interceptors.request.use((config) => {
    const userStore = useUserStore();
    const token = userStore.token;
    const defaultParams = { front: 4, pid: 'a0000' };

    config.params = { 
        ...defaultParams, 
        ...config.params 
    };
    
    if (token) {
        config.headers.Authorization = `Bearer ${ token }`;
    } else {
        const anonymousUserStore = useAnonymousUserStore();
        const anonymousToken = anonymousUserStore.token;

        if(anonymousToken) {
            config.headers.Authorization = `Bearer ${ anonymousToken }`;
        }
    }

    return config;
});

bdApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.data.error_code === "authentication") {
            const anonymousUserStore = useAnonymousUserStore();
            anonymousUserStore.reauthenticate();
        } else if (error.response && error.response.data.error_code === "login_required") {
            confirmAuthPopup();
        } else if (error.response && error.response.data.error_code === "token_expired") {
            const userStore = useUserStore();
            userStore.reauthenticate();
        } else if (error.response && error.response.data.error_code === "token_anonymous_expired") {
            const anonymousUserStore = useAnonymousUserStore();
            anonymousUserStore.reauthenticate();
        }
        return Promise.reject(error);
    }
);

export const fetchUrl = ( url ) => bdApi.get(url);
export const fetchCompanies = async (params = {}) => bdApi.get('/companies', { params });
export const fetchCaptchaToken = ( captcha ) => bdApi.get('/opentoken', { params: { recaptcha_response: captcha }});
