import axios from 'axios';
import { useUserStore } from '@/stores/user';
import { useAnonymousUserStore } from '@/stores/anonymousUser';
import { confirmAuthPopup } from '@/utils/confirmAuthPopup';

export const ppApi = axios.create({
//   baseURL: 'https://api.prospectpro.nl/v1.01'
    baseURL: 'https://api.bedrijfsdata.nl/v1.01'
});

ppApi.interceptors.request.use((config) => {
    const userStore = useUserStore();
    const token = userStore.token;
    const defaultParams = { front: 4, pid: 'a0000' };

    config.params = { 
        ...defaultParams, 
        ...config.params 
    };
    
    if (token) {
        config.headers.Authorization = `Bearer ${ token }`;
    } else {
        const anonymousUserStore = useAnonymousUserStore();
        const anonymousToken = anonymousUserStore.token;

        if(anonymousToken) {
            config.headers.Authorization = `Bearer ${ anonymousToken }`;
        }
    }

    return config;
});

ppApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.data.error_code === "login_required") {
            confirmAuthPopup();
        } else if (error.response && error.response.data.error_code === "token_expired") {
            const userStore = useUserStore();
            userStore.reauthenticate();
        } else if (error.response && error.response.data.error_code === "token_anonymous_expired") {
            const anonymousUserStore = useAnonymousUserStore();
            anonymousUserStore.reauthenticate();
        }
        return Promise.reject(error);
    }
);

export const fetchUrl = ( url ) => ppApi.get(url);

export const login = (email, password) => ppApi.post('/login', { email, password });
export const register = (name, url, email, password) => ppApi.post('/register', { name, url, email, password });
export const passwordReset = (email) => ppApi.post('/password_link', { email });
export const passwordSelect = (password, r) => ppApi.post('/password_reset', { password, r });
export const passwordChange = (password, passwordNew, passwordConfirm) => ppApi.patch('/user', {
  'password-old': password,
  'password': passwordNew,
  'password-confirm': passwordConfirm
});

export const fetchAccountApiKey = ( account_id ) => ppApi.get('/account_key', { params: { account_id }});
export const deleteAccountApiKey = ( account_id ) => ppApi.delete('/account_key', { params: { account_id } });

export const fetchAccounts = async (params = {}) => ppApi.get('/accounts', { params });
export const fetchAccount = async (account_id) => ppApi.get('/accounts', { params: { account_id } });
export const updateAccount = ( account = {} ) => ppApi.patch('/accounts', account);

export const fetchUsers = async (params = {}) => ppApi.get('/user', { params });
export const createUser = (user = {}) => ppApi.post('/user', user);
export const updateUser = (user = {}) => ppApi.patch('/user', user);
export const deleteUser = (user = {}) => ppApi.delete('/user', { params: user });

export const fetchProspects = async (params = {}) => ppApi.get('/prospects', { params });
export const updateProspect = (prospect = {}) => ppApi.post('/prospects', prospect);
export const fetchSuspects = async (params = {}) => ppApi.get('/suspects', { params });

export const fetchAccountTags = ( account_id ) => ppApi.get('/prospects', { 
    params: {
        account_id,
        taglist: "map"
    }
});

export const fetchSuggestions = async (params = {}) => ppApi.get('/suggest', { params });

export const fetchPeople = async (params = {}) => ppApi.get('/people', { params });
export const fetchContacts = async (params = {}) => ppApi.get('/contacts', { params });
export const addContact = (contact = {}) => ppApi.post('/contacts', contact);
export const editContact = (contact = {}) => ppApi.patch('/contacts', contact);
export const deleteContact = (contact = {}) => ppApi.delete('/contacts', { params: contact });

export const fetchPageviews = async (params = {}) => ppApi.get('/leads', { params });