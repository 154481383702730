import router from '@/router';
import { defineStore } from 'pinia';
import { login, register, passwordReset, passwordSelect, passwordChange, updateUser } from '@/services/ppApi';
// import { useLanguageStore } from '@/stores/utils/language';
import { useAccountStore } from '@/stores/account';

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem('pp_auth_token') || null,
    redirectAfterLogin: null,
    user: JSON.parse(localStorage.getItem('pp_user')) || null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    hasUserDetails: (state) => !!state.user,
  },
  actions: {
    setToken(token) {
      this.token = token;
      if (token) {
        localStorage.setItem('pp_auth_token', token);
      } else {
        localStorage.removeItem('pp_auth_token');
      }
    },
    setUser(updatedUser) {
        this.user = { ...this.user, ...updatedUser };
        if(updatedUser) {
            localStorage.setItem('pp_user', JSON.stringify(this.user));
        } else {
            localStorage.removeItem('pp_user');
        }
    },
    resetUser() {
        this.setToken(null);
        this.setUser(null);
        this.$reset;
    },
    reauthenticate() {
        this.resetUser();
        this.setRedirectAfterLogin(router.currentRoute.value.name || null);
        router.push({ name:'login'});
    },
    async login(email, password) {
      try {
        const response = await login(email, password);
        this.setToken(response.data.token);
        this.setUser({
            email,
            name: response.data.name || null
        });

        // const languageStore = useLanguageStore();
        // const userPreferredLanguage = response.data.language;
        // if (userPreferredLanguage) {
        //   await languageStore.setLocale(userPreferredLanguage);
        // }

        if (response.data.default_account_id) {
            const accountStore = useAccountStore();
            const account = {
                account_id: response.data.default_account_id,
            };
            accountStore.setAccount(account);
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    async register(name, url, email, password) {
      try {
        const response = await register(name, url, email, password);
        if (response.status === 200) {
          const loginSuccess = await this.login(email, password);
          return loginSuccess;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    async passwordChange(password, passwordNew, passwordConfirm) {
        try {
          const response = await passwordChange(password, passwordNew, passwordConfirm);
          this.setToken(response.data.token);
          return true;
        } catch (error) {
          return false;
        }
    },
    async passwordReset(email) {
      try {
        await passwordReset(email);
        return true;
      } catch (error) {
        return false;
      }
    },
    async passwordSelect(password, r) {
      try {
        await passwordSelect(password, r);
        return true;
      } catch (error) {
        return false;
      }
    },
    async updatePersonalia(user) {
        try {
            const response = await updateUser(user);
            this.setUser(response.data.users[0]);
            return true;
        } catch (error) {
            return false;
        }
    },
    logout() {
        this.resetUser();
        this.deleteAllSuspectSettings();
        const accountStore = useAccountStore();
        accountStore.resetAccount();
        window.location.reload();
    },
    setRedirectAfterLogin(path = null) {
        if(path) {
            this.redirectAfterLogin = path;
        } else {
            this.redirectAfterLogin = null;
        }
    },
    redirectUserAfterLogin() {
      if (this.redirectAfterLogin) {
        router.push(this.redirectAfterLogin);
        this.redirectAfterLogin = null;
      } else {
        router.push({name: 'suspect'});
      }
    },
    deleteAllSuspectSettings() {
        localStorage.removeItem('pp_suspect_settings');
    },
    getSuspectSettings(account_id = null) {
        const allSuspectSettings = JSON.parse(localStorage.getItem('pp_suspect_settings')) || null;
        if(allSuspectSettings && allSuspectSettings[account_id]) {
            return allSuspectSettings[account_id];
        } else {
             return false;
        }
    },
    saveSuspectSettings(account_id = null, suspect_settings = null) {
        if(!account_id) { return false; }
        var allSuspectSettings = JSON.parse(localStorage.getItem('pp_suspect_settings')) || {};
        if(suspect_settings) {
            allSuspectSettings[account_id] = suspect_settings
        } else if (allSuspectSettings && allSuspectSettings[account_id]) {
            allSuspectSettings.delete(account_id);
        }
        localStorage.setItem('pp_suspect_settings', JSON.stringify(allSuspectSettings));
    },
  }
});