<template>
    <div class="container-fluid content-type-16">
        <div class="container">
            <div class="row row-header">
                <div class="col-12">
                    <div class="content">
                        <a class="logo-link" href="https://www.bedrijfsdata.nl" target="_blank" rel="external noopener">
                            <div class="logo logo-bd-pos"></div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row row-search">
                <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                    <div class="content">
                        <h1 class="h1">{{ $t('views.search.title') }}</h1>
                        <p class="description">{{ $t('views.search.description') }}</p>
                        <SearchBasic />
                        <SearchExamples />
                        <p class="notice" v-html="$t('views.search.notice')"></p>
                    </div>
                </div>
            </div>
            <div class="row row-footer">
                <div class="col-12">
                    <div class="content">
                        <LanguageSwitch />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import LanguageSwitch from '@/components/elements/misc/LanguageSwitch';
    import SearchBasic from '@/components/elements/search/SearchBasic';
    import SearchExamples from '@/components/elements/search/SearchExamples';

    export default {
        name: 'SearchView',
        mixins: [i18nViewMixin],
        components: {
            LanguageSwitch,
            SearchBasic,
            SearchExamples
        },
        data() {
            return {
                seo_title: 'views.search.seo_title',
            };
        }
    }
</script>
