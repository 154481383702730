<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="components.prospect_people.loading_text"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_people.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                                <td>{{ $t('common.name') }}</td>
                                <td>{{ $t('common.job_title') }}</td>
                                <td>{{ $t('common.email') }}</td>
                                <td>{{ $t('common.linkedin') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="person" v-for="person in people.details" :key="person.contact_id">
                                <td class="image">
                                    <ContactImage 
                                        :imgUrl="person.image"
                                        :name_first="person.name_first"
                                        :last_first="person.last_first"
                                    />
                                </td>
                                <td class="company-name">{{ person.name ? person.name : person.first_name && person.last_name ? `${person.first_name} ${person.last_name}` : person.first_name ? person.first_name : person.last_name}}</td>
                                <td class="website">{{ person.job_title }}</td>
                                <td v-if="!person.email && isAuthenticated">
                                    <ul class="emails">
                                        <li v-for="(email, index) in person.emails" :key="index" @click="validateEmail(email, person)" :class="showEmailVerification(email) ? 'verification-link' : ''">
                                            <div v-if="email.email" class="email">{{ email.email }}</div>
                                            <div v-if="email.confidence || email.api" class="details">

                                                <div v-if="email.confidence && showEmailVerification(email)" class="confidence">{{ email.confidence }}<span>%</span></div>
                                                
                                                <div v-if="showEmailVerification(email)" class="status">
                                                    <div v-if="loadingEmailValidation.includes(email.email)" class="loading-spinner-type-1 primary"></div>
                                                    <div v-else-if="email.status && (email.status === 'valid' || email.status === 'unknown')" class="indicator-type-1 validate"></div>
                                                    <div v-else-if="email.status === 'invalid'" class="indicator-type-1 invalid"></div>
                                                    <div v-else class="indicator-type-1 valid"></div>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td v-else-if="!person.email && person.emails[0] && person.emails[0].email" class="email">
                                    <a v-if="!person.emails[0].email.includes('***')" :href="'mailto:' + person.emails[0].email" target="_blank" rel="noreferrer external">
                                        {{ person.emails[0].email }}
                                    </a>
                                    <template v-else>
                                        {{ person.emails[0].email }}
                                    </template>
                                </td>
                                <td v-else class="email">
                                    <a v-if="person.email && !person.email.includes('***')" :href="'mailto:' + person.email" target="_blank" rel="noreferrer external">
                                        {{ person.email }} <div v-if="person.emailVerified && isAuthenticated" class="indicator-type-1 valid" style="display:inline-block;"></div>
                                    </a>
                                    <template v-else>
                                        {{ person.email }} <div v-if="person.emailVerified && isAuthenticated" class="indicator-type-1 valid" style="display:inline-block;"></div>
                                    </template>
                                </td>
                                <td v-if="person.linkedin_link" class="linkedin">
                                    <a v-if="!person.linkedin_link.includes('***')" :href="person.linkedin_link" target="_blank" rel="noreferrer external">
                                        {{ person.linkedin_link }}
                                    </a>
                                    <template v-else>
                                        {{ person.linkedin_link }}
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';

    import { fetchUrl } from '@/services/ppApi';
    import { markRaw } from 'vue';
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import AddContactForm from '@/components/forms/AddContactForm';
    import ContactImage from '@/components/elements/misc/ContactImage';

    export default {
        name: "ProspectPeopleFreemium",
        components: {
            WidgetSection,
            ContactImage
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            isAuthenticated: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                loadingEmailValidation: []
            }
        },
        computed: {
            hide() {
                return !this.people || (!this.loading && this.loaded && (!this.people.details || this.people.details.length < 1));
            },
            people() {
                return useProspectStore().peopleDetails;
            }
        },
        methods: {
            async fetchPeople() {
                if (!this.people || (this.people.api_urls && this.people.api_urls?.length < 1) || this.loaded || this.loading) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.people.api_urls[0]);
                    this.people.details = response.data.people;
                    this.loaded = true;
                } catch {
                    this.people.details = null;
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                }
            },
            async validateEmail(emailItem, person) {
                if(!this.showEmailVerification(emailItem) && !this.loadingEmailValidation.includes(emailItem.email)) { return; }
                this.loadingEmailValidation.push(emailItem.email);

                try {
                    const response = await fetchUrl(emailItem.api);
                    if (response?.data?.verified === 1) {
                        person.email = emailItem.email;
                        person.emailVerified = true;
                    } else if (response?.data?.verified === 0) {
                        emailItem.confidence = null;
                        emailItem.status = 'invalid';
                    } else {
                        useToastStore().addToast(i18n.global.t('toasts.error.email_validation_failed'), 'error');
                    }
                } catch {
                    useToastStore().addToast(i18n.global.t('toasts.error.email_validation_failed'), 'error');
                } finally {
                    this.loadingEmailValidation = this.loadingEmailValidation.filter(e => e !== emailItem.email);
                }
            },
            addContactPopup(person) {
                usePopupStore().showPopup('form', {
                    title: 'popups.create_contact.title',
                    description: 'popups.create_contact.description',
                    formComponent: markRaw(AddContactForm),
                    formProps: {
                        person: person
                    },
                    onFormSuccess: () => {
                        useToastStore().addToast(i18n.global.t('toasts.success.contact_created'), 'success');
                    }
                });
            },
            showEmailVerification(emailItem) {
                return emailItem.api && (!emailItem.checked_ago || emailItem.checked_ago > 1);
            },
            async handleLoadingStart() {
                if (!this.loaded) {
                    await this.fetchPeople();
                    this.$refs.WidgetSection.toggleSection();
                }
            }
        },
        mounted() {
            if(!this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchPeople();
            }
        }
    }
</script>