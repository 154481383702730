<template>
    <SearchViewBase :loading="loading" :error="error">
        <SearchDetails v-if="prospect" />
    </SearchViewBase>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import { useProspectStore } from '@/stores/prospect';
    import { useCompanyStore } from '@/stores/company';
    import SearchViewBase from '@/views/search/SearchViewBase';
    import SearchDetails from '@/components/search/SearchDetails';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'SearchView',
        props: {
            id: {
                type: String,
                required: true
            }
        },
        components: {
            SearchViewBase,
            SearchDetails
        },
        mixins: [i18nViewMixin],
        data() {
            return {
                seo_title: 'views.search.seo_title',
				title: 'views.search.title',
				description: 'views.search.description'
            };
        },
        computed: {
            ...mapState(useProspectStore, {
                prospect: 'prospect'
            }),
            ...mapState(useCompanyStore, ['loading', 'error'])
        },
        methods: {
            ...mapActions(useCompanyStore, ['fetchCompany', 'resetCompany']),
            ...mapActions(useProspectStore, ['resetProspect']),
        },
        watch: {
            '$route.params.id': {
                handler(newId) {
                    if(!this.loading) {
                        scrollToTop();
                        this.fetchCompany(newId, this.$router);
                    }
                },
                immediate: true
            }
        },
        mounted() {
            scrollToTop();
            
            if(this.id) {
                if(!this.loading) {
                    this.fetchCompany(this.id, this.$router);
                }
            } else {
                this.$router.push({ name:'home' });
            }
        },
        unmounted() {
            this.resetProspect();
            this.resetCompany();
        }
    }
</script>
