import router from '@/router';
import { defineStore } from 'pinia';
import { fetchCaptchaToken } from '@/services/bdApi';
import { useAccountStore } from '@/stores/account';

export const useAnonymousUserStore = defineStore('anonymousUser', {
    state: () => ({
        token: localStorage.getItem('pp_anonymous_auth_token') || null,
        tokenExpiry: localStorage.getItem('pp_anonymous_auth_token_expiry') || null,
        redirectAfterLogin: null
    }),
    getters: {
        isAuthenticated: (state) => {
            const now = new Date().getTime();
            return state.token && state.tokenExpiry && now < state.tokenExpiry;
        },
    },
    actions: {
        setToken(token) {
            if (token) {
                const now = new Date().getTime();
                const expiryTime = now + 10 * 60 * 60 * 1000;

                this.token = token;
                this.tokenExpiry = expiryTime;

                localStorage.setItem('pp_anonymous_auth_token', token);
                localStorage.setItem('pp_anonymous_auth_token_expiry', expiryTime);
            } else {
                this.token = null;
                this.tokenExpiry = null;

                localStorage.removeItem('pp_anonymous_auth_token');
                localStorage.removeItem('pp_anonymous_auth_token_expiry');
            }
        },
        resetUser() {
            this.setToken(null);
            this.$reset;
        },
        reauthenticate() {
            this.resetUser();
            this.setRedirectAfterLogin(router.currentRoute.value.path || null);
            router.push({ name:'anonymous-login'});
        },
        async captchaLogin(captcha) {
            try {
                const response = await fetchCaptchaToken(captcha);
                this.setToken(response.data.token);

                const accountStore = useAccountStore();
                const account = {
                    account_id: response.data.account_id || 'a0000',
                };
                accountStore.setAccount(account);

                return true;
            } catch (error) {
                return false;
            }
        },
        setRedirectAfterLogin(path = null) {
            if(path) {
                this.redirectAfterLogin = path;
            } else {
                this.redirectAfterLogin = null;
            }
        },
        redirectUserAfterLogin() {
            if (this.redirectAfterLogin) {
                router.push(this.redirectAfterLogin);
                this.redirectAfterLogin = null;
            } else {
                router.push({name: 'home'});
            }
        },
    }
});