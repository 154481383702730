<template>
    <a class="logo-link-2" href="https://www.bedrijfsdata.nl" target="_blank" rel="external noopener">
        <div class="logo-bd-pos"></div>
    </a>
    <h1 class="h4">{{ $t('components.robot_check.title') }}</h1>

    <p v-if="loading" class="description">{{ $t( 'components.robot_check.loading' ) }}</p>
    <p v-else-if="error && attempt > 5" class="description">{{ $t( 'components.robot_check.error.authenticate' ) }}</p>
    <p v-else-if="error" class="description">{{ $t( 'components.robot_check.error.' + errorMessage ) }}</p>
    <p v-else class="description">{{ $t('components.robot_check.description') }}</p>

    <div v-if="loading" class="loading-spinner-type-2"></div>
    <button v-else @click="authenticate" class="btn-s btn-1" :disabled="loading">
        <span>{{ $t('components.robot_check.button.default') }}</span>
    </button>

    <!-- <button v-else-if="attempt < 5" @click="authenticate" class="btn-s btn-1" :disabled="loading">
        <span>{{ $t('components.robot_check.button.default') }}</span>
    </button>
    <template v-else>
        <button class="btn-s btn-1" :disabled="loading" @click="$router.push({ name: 'register' })">
            <span>{{ $t('components.robot_check.button.register') }}</span>
        </button>
        <button class="btn-s btn-4" :disabled="loading" @click="$router.push({ name: 'login' })">
            <span>{{ $t('components.robot_check.button.login') }}</span>
        </button>
    </template> -->
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { useReCaptcha } from 'vue-recaptcha-v3';
    import { useAnonymousUserStore } from '@/stores/anonymousUser';

    export default {
        name: 'CaptchaAuth',
        setup() {
            const error = ref(false);
            const errorMessage = ref('');
            const loading = ref(false);
            const attempt = ref(0);
            const { executeRecaptcha } = useReCaptcha();
            const anonymousUserStore = useAnonymousUserStore();

            const authenticate = async () => {
                attempt.value++;

                try {
                    loading.value = true;
                    error.value = false;
                    errorMessage.value = '';

                    const captcha = await executeRecaptcha('pageview');

                    if (!captcha) {
                        if(attempt.value < 2) {
                            setTimeout(() => {
                                authenticate();
                            }, 500);
                        }

                        error.value = true;
                        errorMessage.value = 'default';
                        return;
                    }

                    const success = await anonymousUserStore.captchaLogin(captcha);

                    if (!success) {
                        error.value = true;
                        errorMessage.value = 'auto_failed';
                    } else {
                        anonymousUserStore.redirectUserAfterLogin();
                    }
                } catch (err) {
                    error.value = true;
                    errorMessage.value = 'default';
                } finally {
                    loading.value = false;
                }
            };

            onMounted(() => {
                anonymousUserStore.resetUser();
                authenticate();
            });

            return {
                attempt,
                loading,
                error,
                errorMessage,
                authenticate,
            };
        },
    };
</script>
