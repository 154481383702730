<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @toggle="handleToggle"
        @loading-start="handleLoadingStart"
        cssClass="slider-type-1"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_vacancies.name')}}
            </div>
        </template>

        <template v-slot:section-actions>
            <div class="swiper-navigation">
                <div :class="`swiper-button-prev-${uniqueId}`"></div>
                <div :class="`swiper-button-next-${uniqueId}`"></div>
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-slider">
                <swiper-container ref="swiper" init="false">
                    <swiper-slide v-for="(vacancy, index) in vacancies.details" :key="index">
                        <component :is="vacancy.url ? 'a' : 'div'" :href="vacancy.url || undefined" target="_blank" rel="noreferrer external" class="slide-container">
                            <div class="bar">
                                <div class="left">
                                    <CompanyLogo 
                                        :domain="vacancy.domain ? vacancy.domain : null"
                                        :company_name="vacancy.company ? vacancy.company : vacancy.domain ? vacancy.domain : null"
                                    />
                                </div>
                                <div class="right">
                                    <div class="title" v-if="vacancy.vacancy || vacancy.title">{{ vacancy.vacancy ? vacancy.vacancy : vacancy.title }}</div>
                                    <div class="date" v-if="vacancy.date">{{ formatDate(vacancy.date) }}</div>
                                </div>
                            </div>
                            <div class="description vacancy">{{ vacancy.description }}</div>
                            <div class="url" v-if="vacancy.url">{{ vacancy.url }}</div>
                            <div class="status" v-else>{{ $t('components.prospect_vacancies.expired') }}</div>
                        </component>
                    </swiper-slide>
                </swiper-container>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import { nextTick } from 'vue';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            CompanyLogo
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false
            }
        },
        computed: {
            vacancies() {
                return useProspectStore().vacancies;
            },
            hide() {
                return !this.vacancies || (!this.loading && this.loaded && (!this.vacancies.details || this.vacancies.details.length < 1));
            },
            uniqueId() {
                return Math.random().toString(36).substr(2, 9);
            },
        },
        methods: {
            async fetchVacancy() {
                if (!this.vacancies || (this.vacancies.api_urls && this.vacancies.api_urls.length < 1) || this.loaded) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.vacancies.api_urls[0]);
                    this.vacancies.details = response.data.vacancies;
                    this.loaded = true;
                } catch {
                    this.vacancies.details = null;
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                    await nextTick();
                    this.initSwiper();
                }
            },
            initSwiper() {
                if(!this.$refs.swiper) { return; }
                
                const swiperEl = this.$refs.swiper;
                const swiperParams = {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    breakpoints: {
                        769: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        }
                    },
                    navigation: {
                        nextEl: `.swiper-button-next-${this.uniqueId}`,
                        prevEl: `.swiper-button-prev-${this.uniqueId}`
                    }
                };
                Object.assign(swiperEl, swiperParams);
                swiperEl.initialize();
            },
            formatDate(date) {
                const [year, month, day] = date.split('-');
                return `${day}-${month}-${year}`;
            },
            handleToggle(isVisible) {
                if (isVisible) {
                    this.$nextTick(() => {
                        this.initSwiper();
                    });
                }
            },
            async handleLoadingStart(isVisible) {
                if (!this.loaded) {
                    await this.fetchVacancy();
                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                } else {
                    this.handleToggle(isVisible);
                }
            }
        },
        mounted() {
            if(!this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchVacancy();
            }
        }
    }
</script>