import { defineStore } from 'pinia';

export const useUserSettingsStore = defineStore('userSettings', {
    state: () => ({
        // prefs: JSON.parse(localStorage.getItem('pp_user_prefs')) || {},
        history: JSON.parse(localStorage.getItem('pp_user_history')) || []
    }),
    actions: {
        // setPreferences(updatedPreferences) {
        //     this.prefs = { ...this.prefs, ...updatedPreferences };
        //     if(updatedPreferences) {
        //         localStorage.setItem('pp_user_prefs', JSON.stringify(this.prefs));
        //     } else {
        //         localStorage.removeItem('pp_user_prefs');
        //     }
        // },
        addToHistory(company) {
            if(!company?.id || !company?.name) {
                return;
            }

            this.history = this.history.filter(item => item.id !== company.id + (!company.ch.includes('_') && company.ch ? '_' + company.ch : ''));

            if (this.history.length >= 6) {
                this.history.pop();
              }

            this.history.unshift({
                id: company.id + (!company.ch.includes('_') && company.ch ? '_' + company.ch : ''),
                name: company.name,
                domain: company.domain || null
            });

            localStorage.setItem('pp_user_history', JSON.stringify(this.history));
        },
    },
});
