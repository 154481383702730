<template>
    <component v-if="mainNav.showNav" :is="currentNavType"></component>
</template>

<script>
    import { useMainNavStore } from '@/stores/mainNav';
    // import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    // import { useUserStore } from '@/stores/user';
    // import { useAccountStore } from '@/stores/account';
    // import TopNav from '@/components/navigation/TopNav';
    // import TopNavAccounts from '@/components/navigation/TopNavAccounts';
    // import SideNav from '@/components/navigation/SideNav';

    import TopNavSearch from '@/components/navigation/TopNavSearch';

    export default {
        name: 'MainNav',
        components: {
            // TopNav,
            // TopNavAccounts,
            // SideNav,
            TopNavSearch
        },
        computed: {
            mainNav() {
                return useMainNavStore();
            },
            // screenWidthStore() {
            //     return useScreenWidthStore();
            // },
            // userStore() {
            //     return useUserStore();
            // },
            // accountStore() {
            //     return useAccountStore();
            // },
            // currentNavType() {
            //     if (this.$route.name === 'search') {
            //         return 'TopNavSearch';
            //     } else if (this.accountStore.hasAccountId) {
            //         if(this.screenWidthStore.width >= 769) {
            //             return 'SideNav';
            //         } else {
            //             return 'TopNav';
            //         }
            //     } else {
            //         return 'TopNavAccounts';
            //     }
            // },
            currentNavType() {
                if (this.$route.name === 'search') {
                    return 'TopNavSearch';
                } else {
                    return 'none';
                }
            }
        },
        methods: {
            // toggleNav() {
            //     if (this.$route.name === 'search' || this.userStore.isAuthenticated) {
            //         this.mainNav.toggleNav(true);
            //     } else {
            //         this.mainNav.toggleNav(false);
            //     }
            // },
            toggleNav() {
                if (this.$route.name === 'search') {
                    this.mainNav.toggleNav(true);
                } else {
                    this.mainNav.toggleNav(false);
                }
            }
        },
        watch: {
            $route() {
                this.toggleNav();
            }
        },
        created() {
            this.toggleNav();
        }
    }
</script>
