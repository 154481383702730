<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_ai.models.' + slug)}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div v-if="data && slug === 'summary'" class="col-12 col-sm-10 col-md-8 col-lg-6 summary">
                    <p>{{ data[0] }}</p>
                </div>
                <template v-else-if="data && slug === 'fundamentals'">
                    <ProspectAiFundamentals :data="data" />
                </template>
                <template v-else-if="data">
                    <ProspectAiHelper :data="data" />
                </template>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import i18n from "@/plugins/i18n";
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import ProspectAiHelper from '@/components/elements/prospect/ProspectAiHelper';
    import ProspectAiFundamentals from '@/components/elements/prospect/ProspectAiFundamentals';

    export default {
        name: "ProspectAiWidget",
        components: {
            WidgetSection,
            ProspectAiHelper,
            ProspectAiFundamentals
        },
        props: {
            slug: {
                type: String,
                required: true
            },
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false
            }
        },
        computed: {
            ai() {
                return useProspectStore().ai;
            },
            model() {
                return this.ai[this.slug + '_' + this.currentLocale] || this.ai[this.slug] || false;
            },
            hide() {
                return !this.slug || !this.model;
            },
            data() {
                switch (this.slug) {
                    case 'summary' :
                        return this.model?.details?.description?.length > 0 ? this.model.details.description : false;
                    default :
                        return this.model?.details ? this.model.details : false;
                }
            },
            currentLocale() {
                return i18n.global.locale;
            }
        },
        methods: {
            deleteModelOption() {
                useProspectStore().deleteAiOption(this.model.slug);
            },
            async fetchAiModel() {
                if (!this.model || this.loaded) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await fetchUrl(this.model.api_urls[0]);

                    if(response.data?.summary && (response.data?.summary.length > 0 || Object.keys(response.data.summary).length > 0)) {
                        this.model.details = response.data.summary;
                        this.loaded = true;
                    } else {
                        this.deleteModelOption();
                    }
                } catch {
                    this.deleteModelOption();
                } finally {
                    this.loading = false;
                }
            },
            async handleLoadingStart() {
                if(!this.loaded) {
                    await this.fetchAiModel();
                    if(!this.hide) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            }
        },
        mounted() {
            if(!this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchAiModel();
            }
        },
        watch: {
            currentLocale() {
                if(this.loaded && this.model?.slug && this.model.slug.includes('_') && !this.data) {
                    this.$refs.WidgetSection.toggleSection();
                    this.loaded = false;
                    this.handleLoadingStart();
                }
            }
        }
    }
</script>