import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { pinia } from '@/stores';
import i18n from '@/plugins/i18n';
import { useLanguageStore } from '@/stores/utils/language';
import { register as registerSwiper } from 'swiper/element/bundle';
import SwiperCore, { Navigation } from 'swiper/core';
import { VueReCaptcha } from 'vue-recaptcha-v3'; 

import "@/scss/style.scss";

SwiperCore.use([Navigation]);
registerSwiper();

const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(i18n);

app.use(VueReCaptcha, {
    siteKey: '6LeNydAlAAAAAFBXDbkgVqUlWr6qPFxMvVzFpU8w',
    loaderOptions: {
        autoHideBadge: true,
    }
});

const languageStore = useLanguageStore();

languageStore.initLocale().then(() => {
    app.mount('#app');
});