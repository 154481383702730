import { createRouter, createWebHistory } from 'vue-router';
// import { ifNotAuthenticatedRedirect, ifAuthenticatedRedirect, ifNotAuthenticatedAnonymousRedirect, hasQueryParamPasswordSelect, hasAccountId } from '@/router/guards';
import { ifAuthenticatedRedirect, ifNotAuthenticatedAnonymousRedirect } from '@/router/guards';
import HomeView from '@/views/HomeView';
import SearchView from '@/views/search/SearchView';
import AnonymousLoginView from '@/views/authentication/AnonymousLoginView';

// import AccountsView from '@/views/AccountsView';
// import ProfileView from '@/views/ProfileView';
// import AccountSettingsView from '@/views/account/SettingsView';
// import AccountUsersView from '@/views/account/UsersView';
// import SuspectView from '@/views/account/SuspectView';
// import ProspectsView from '@/views/account/ProspectsView';
// import ProspectView from '@/views/account/ProspectView';

// import LoginView from '@/views/authentication/LoginView';
// import PasswordResetView from '@/views/authentication/PasswordResetView';
// import PasswordSelectView from '@/views/authentication/PasswordSelectView';
// import RegisterView from '@/views/authentication/RegisterView';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        beforeEnter: [ifNotAuthenticatedAnonymousRedirect]
    },
    {
        path: '/s/:id',
        name: 'search',
        component: SearchView,
        props: true,
        beforeEnter: [ifNotAuthenticatedAnonymousRedirect]
    },
    {
        path: '/anonymous-login',
        name: 'anonymous-login',
        component: AnonymousLoginView,
        beforeEnter: ifAuthenticatedRedirect
    },
    // {
    //     path: '/settings',
    //     name: 'settings',
    //     component: AccountSettingsView,
    //     beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    // },
    // {
    //     path: '/suspect',
    //     name: 'suspect',
    //     component: SuspectView,
    //     beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    // },
    // {
    //     path: '/prospects',
    //     name: 'prospects',
    //     component: ProspectsView,
    //     beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    // },
    // {
    //     path: '/prospects/:id',
    //     name: 'prospect',
    //     component: ProspectView,
    //     beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    //     props: true
    // },
    // {
    //     path: '/users',
    //     name: 'users',
    //     component: AccountUsersView,
    //     beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    // },
    // {
    //     path: '/accounts',
    //     name: 'accounts',
    //     component: AccountsView,
    //     beforeEnter: ifNotAuthenticatedRedirect
    // },
    // {
    //     path: '/profile',
    //     name: 'profile',
    //     component: ProfileView,
    //     beforeEnter: ifNotAuthenticatedRedirect
    // },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: LoginView,
    //     beforeEnter: ifAuthenticatedRedirect
    // },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: RegisterView,
    //     beforeEnter: ifAuthenticatedRedirect
    // },
    // {
    //     path: '/password-reset',
    //     name: 'passwordReset',
    //     component: PasswordResetView,
    //     beforeEnter: ifAuthenticatedRedirect
    // },
    // {
    //     path: '/password-select',
    //     name: 'passwordSelect',
    //     component: PasswordSelectView,
    //     beforeEnter: [ifAuthenticatedRedirect, hasQueryParamPasswordSelect],
    //     props: (route) => ({ r: route.query.r })
    // },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
