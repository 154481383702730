<template>
	<div class="login-view">
		<AuthSection>
            <CaptchaAuth />
		</AuthSection>
	</div>
</template>

<script>
	import i18nViewMixin from '@/mixins/i18nViewMixin';
	import AuthSection from '@/components/sections/AuthSection';
    import CaptchaAuth from '@/components/elements/misc/CaptchaAuth';

	export default {
		name: 'AnonymousLoginView',
		mixins: [i18nViewMixin],
		components: {
			AuthSection,
            CaptchaAuth
		},
		data() {
			return {
				seo_title: 'views.login_anonymous.seo_title',
				title: 'views.login_anonymous.title',
				description: 'views.login_anonymous.description',
			};
		}
	}
</script>